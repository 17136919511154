import styled from 'styled-components'

export const ImageWrapper = styled.div`
	${tw`bg-yellow flex-none`};

	.LazyLoad {
		/* height: 225px;
		background: pink; */
		width: 100%;
		position: relative;
		background: #eee;
		-webkit-transition: background 0.5s;
		transition: background 0.5s;
		overflow: hidden;
		object-fit: cover;
		-webkit-box-flex: 1;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		/* padding-bottom: 50%; */
	}
	.LazyLoad.is-visible {
		/* height: auto; */
		/* height: 225px; */
		/* background: none !important; */
	}

	.LazyLoad img {
		position: absolute !important;
		width: 100%;
		/* height: auto; */
		left: 0;
		top: 0;
		/* opacity: 0; */
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
		-webkit-transition: opacity 0.4s, -webkit-transform 0.6s;
		transition: opacity 0.4s, -webkit-transform 0.6s;
		transition: opacity 0.4s, transform 0.6s;
		transition: opacity 0.4s, transform 0.6s, -webkit-transform 0.6s;
	}

	.LazyLoad.is-visible img {
		/* opacity: 1 !important; */
		transform: scale(1) !important;
	}
`
