import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import {
	FaFacebookF,
	FaInstagram,
	FaLinkedinIn,
	FaTumblr,
	FaTwitter,
	FaVimeo
} from 'react-icons/fa'

const FooterWrapper = styled.footer`
	${tw`bg-grey-darkest py-12 relative flex flex-col justify-center`};
`

const FlexWrap = styled.div`
	${tw`flex flex-wrap leading-loose -mx-3`};
`

const FooterContactWrap = styled.div`
	${tw`w-full md:w-1/2 px-3 text-center md:text-left`};
`

const FooterContact = styled.div`
	${tw`px-6 mx-auto`};
`

const FooterP = styled.p`
	${tw`mb-4 uppercase tracking-wider text-sm text-white font-semibold`};
	&:last-of-type {
		${tw`mb-0`};
	}
`

const SocialMediaHolder = styled.div`
	${tw`relative md:absolute inline-block mx-auto md:pin-r md:pin-b mt-6 md:mt-0 mb-8 px-3 py-3 md:bg-black`};
`

const ItemList = styled.ul`
	${tw`list-reset inline-flex items-center`};
`

const Item = styled.li`
	${tw`block`};
	transition: 0.4s all;
	transform: scale(1);
	&:hover {
		transform: scale(1);
	}
	&:hover span {
		transform: scale(1.4);
	}
`

const ItemLink = styled.a`
	${tw`uppercase text-grey px-4 py-2 hover:text-grey-light`};
	transition: 0.4s all;
	&:hover {
		span {
			transform: scale(1.1);
		}
	}
`

const IconHolder = styled.span`
	${tw``};
	transform: scale(1);
	transition: 0.4s all;
`

export default () => (
	<StaticQuery
		query={graphql`
			query {
				craft {
					sites {
						name
					}
					globals {
						contact {
							primaryContact {
								address
								cityStateZip
								phone
								email
								twitter
								vimeo
								facebook
								instagram
								tumblr
							}
						}
					}
				}
			}
		`}
		render={data => (
			<FooterWrapper>
				<FlexWrap>
					<FooterContactWrap>
						<FooterContact>
							<FooterP>
								{data.craft.sites[0].name}
								<br />
								{data.craft.globals.contact.primaryContact[0].address} <br />
								{data.craft.globals.contact.primaryContact[0].cityStateZip}
							</FooterP>
							<FooterP>
								{data.craft.globals.contact.primaryContact[0].phone}
								<br />
								{data.craft.globals.contact.primaryContact[0].email}{' '}
							</FooterP>
						</FooterContact>
					</FooterContactWrap>
				</FlexWrap>
				<SocialMediaHolder>
					<ItemList>
						{data.craft.globals.contact.primaryContact[0].twitter ? (
							<Item>
								<ItemLink
									href={data.craft.globals.contact.primaryContact[0].twitter}
									rel="nofollow"
									target="blank"
								>
									<IconHolder>
										<FaTwitter size="1.25rem" />
									</IconHolder>
								</ItemLink>
							</Item>
						) : null}
						{data.craft.globals.contact.primaryContact[0].facebook ? (
							<Item>
								<ItemLink
									href={data.craft.globals.contact.primaryContact[0].facebook}
									rel="nofollow"
									target="blank"
								>
									<IconHolder>
										<FaFacebookF size="1.25rem" />
									</IconHolder>
								</ItemLink>
							</Item>
						) : null}
						{data.craft.globals.contact.primaryContact[0].instagram ? (
							<Item>
								<ItemLink
									href={data.craft.globals.contact.primaryContact[0].instagram}
									rel="nofollow"
									target="blank"
								>
									<IconHolder>
										<FaInstagram size="1.25rem" />
									</IconHolder>
								</ItemLink>
							</Item>
						) : null}
						{data.craft.globals.contact.primaryContact[0].vimeo ? (
							<Item>
								<ItemLink
									href={data.craft.globals.contact.primaryContact[0].vimeo}
									rel="nofollow"
									target="blank"
								>
									<IconHolder>
										<FaVimeo size="1.25rem" />
									</IconHolder>
								</ItemLink>
							</Item>
						) : null}
					</ItemList>
				</SocialMediaHolder>
			</FooterWrapper>
		)}
	/>
)
