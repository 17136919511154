import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
// import Header from './header.js'
import Footer from './Footer'

const PrimaryBackground = styled.div`
	// ${tw`md:m-6 md:mt-0 bg-grey-lighter`};
	// px-3 md:px-8
`

class Layout extends React.Component {
	render() {
		const {
			location,
			coloring,
			headerPosition,
			headerColor,
			children
		} = this.props
		return (
			<div className="w-full" data-pagetheme={coloring || 'none'}>
				<PrimaryBackground>{children}</PrimaryBackground>
				<Footer />
			</div>
		)
	}
}

Layout.propTypes = {
	coloring: PropTypes.string
}

export default Layout
