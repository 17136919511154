import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Structure/Layout'
import Image from '../components/Atoms/Image'

const Box = styled.div`
	${tw`bg-yellow min-h-300 p-8`};
	pre {
		white-space: pre-wrap;
	}
`

const GridWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	justify-items: stretch;
	align-items: stretch;
	position: relative;
	margin: 20px 0;

	.tg-item {
		${tw`min-h-300 bg-yellow-dark relative flex flex-col`};
		background: url('https://placehold.it/600x400');
	}

	.tg-1 {
		grid-area: 1 / 1 / 2 / 2;
	}

	.tg-2 {
		grid-area: 1 / 2 / 2 / 3;
	}

	.tg-3 {
		grid-area: 1 / 3 / 2 / 4;
	}

	.tg-4 {
		grid-area: 1 / 4 / 2 / 5;
	}

	.tg-5 {
		grid-area: 2 / 1 / 3 / 2;
	}

	.tg-6 {
		grid-area: 2 / 2 / 3 / 4;
	}

	.tg-7 {
		grid-area: 2 / 4 / 3 / 5;
	}

	.tg-8 {
		grid-area: 3 / 1 / 5 / 3;
	}

	.tg-9 {
		grid-area: 3 / 3 / 4 / 4;
	}

	.tg-10 {
		grid-area: 3 / 4 / 4 / 5;
	}

	.tg-11 {
		grid-area: 4 / 3 / 5 / 4;
	}

	.tg-12 {
		grid-area: 4 / 4 / 5 / 5;
	}

	.tg-13 {
		grid-area: 5 / 1 / 6 / 2;
	}

	.tg-14 {
		grid-area: 5 / 2 / 6 / 3;
	}

	.tg-15 {
		grid-area: 5 / 3 / 6 / 5;
	}
`

const Bgimg = styled.div`
	${tw`flex flex-grow w-full h-full bg-white bg-cover bg-no-repeat bg-center`};
`

class TeamTooPage extends Component {
	render() {
		const {
			data: {
				craft: { entries }
			}
		} = this.props

		const theImages = entries.map(entry =>
			!entry.featuredImage || !entry.featuredImage.length
				? null
				: entry.featuredImage[0].url
		)

		const filteredImages = theImages.filter(function(el) {
			return el != null
		})

		filteredImages.splice(
			5,
			0,
			'https://pensa.imgix.net/primary/WObJFgY0zNo.jpg'
		)

		filteredImages.splice(
			7,
			0,
			'https://pensa.imgix.net/primary/mHpJToohk1Y.jpg'
		)

		return (
			<Layout location={this.props.location}>
				<Box>
					<h2>Team</h2>
					<GridWrapper>
						{filteredImages.map((entry, i) => (
							<div key={i} className={'tg-item ' + 'tg-' + (i + 1)}>
								<Bgimg
									style={{
										backgroundImage:
											'url(' +
											entry +
											'?fm=jpg&auto=compress,enhance,format&fit=crop&h=400&w=400&sat=-100' +
											')'
									}}
								/>
							</div>
						))}
					</GridWrapper>
					<pre>{JSON.stringify(filteredImages, null, 2)}</pre>
				</Box>
			</Layout>
		)
	}
}

export default TeamTooPage

export const TeamTooPageQuery = graphql`
	query TeamTooQuery {
		craft {
			entries(section: [team], limit: null) {
				... on Craft_Team {
					title
					uri
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
				}
			}
		}
	}
`

// <pre>{JSON.stringify(entry, null, 2)}</pre>

// https://pensa.imgix.net/primary/WObJFgY0zNo.jpg
// https://pensa.imgix.net/primary/mHpJToohk1Y.jpg
// https://pensa.imgix.net/primary/pMpOKu8rO3c.jpg
